<template>
  <div
    class="publicNav flex"
    :class="[deviceInfo.type]"
    :style="{
      background: `linear-gradient(90deg,${currentSkin.topBg[0]} 0%,${currentSkin.topBg[1]} 100%)`,
    }"
  >
    <div class="min-480 title flex">
      <span
        v-if="layoutMode == 'navFull'"
        :style="{
          color: currentSkin ? currentSkin.topColor : '',
        }"
      >
        <span style="font-size: 26px; margin-left: 20px; letter-spacing: 2px"
          >适界开放平台</span
        >
        <!-- <logo-box :toggle-open="false"></logo-box> -->
        <!-- <el-popover placement="bottom" trigger="click">
					<div class="langList">
						<h3>{{ $t("public.language") }}</h3>
						<div
							class="flex"
							:class="{ langListCheck: lang.isCheck }"
							v-for="lang in langEnum"
							:key="lang.type"
							@click="handleChangeLang(lang)"
						>
							<img :src="lang.icon" alt="" />
							<span>
								{{ lang.label }}
							</span>
						</div>
					</div>
					<div class="flex langBox" slot="reference">
						<img :src="showLang.icon" alt="" />
						<span>{{ showLang.label }}</span>
						<i class="el-icon-arrow-down"></i>
					</div>
				</el-popover> -->
      </span>

      <el-breadcrumb
        separator="/"
        style="margin-left: 10px"
        v-if="showBreadcrumb"
      >
        <el-breadcrumb-item v-for="item in breadcrumb" :key="item.name">
          <em
            class="breadcrumbSpan"
            :style="{
              color: currentSkin ? currentSkin.topColor : '',
            }"
          >
            {{ item.label }}
          </em>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="min-480 topNav" v-if="navMode != 'default'">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        :background-color="currentSkin.topBg[0]"
        text-color="#ffffff"
        active-text-color="#0077FF"
      >
        <el-menu-item v-for="item in nav" :key="item.name" :index="item.name">{{
          item.meta.title
        }}</el-menu-item>
      </el-menu>
    </div>

    <div class="max-480 flex small">
      <div
        class="flex small-icon"
        :class="{ flexd: isSideOpen }"
        @click="toggleOpenSide"
      >
        <i
          class="el-icon-menu"
          v-if="!isSideOpen"
          :style="{
            color: currentSkin ? currentSkin.topColor : '',
          }"
        ></i>
        <i
          class="el-icon-error"
          v-else
          :style="{
            color: currentSkin ? currentSkin.topColor : '',
            fontSize: '20px',
          }"
        ></i>
      </div>
      <el-popover placement="bottom" trigger="click">
        <div class="langList">
          <h3>{{ $t("public.language") }}</h3>
          <div
            class="flex"
            :class="{ langListCheck: lang.isCheck }"
            v-for="lang in langEnum"
            :key="lang.type"
            @click="handleChangeLang(lang)"
          >
            <img :src="lang.icon" alt="" />
            <span>
              {{ lang.label }}
            </span>
          </div>
        </div>
        <div class="flex langBox" slot="reference">
          <img :src="showLang.icon" alt="" />
          <span class="omit-1">{{ showLang.label }}</span>
          <!-- <i class="el-icon-arrow-down"></i> -->
          <i class="el-icon-arrow-down"></i>
        </div>
      </el-popover>
    </div>
    <div
      class="max-480 small-title"
      :style="{
        color: currentSkin ? currentSkin.topColor : '',
      }"
    >
      SN：1121112095
    </div>
    <div class="account flex">
      <!-- <span class="min-480 account-sn" v-if="snName"> SN：{{ snName }}</span>
			<img
				class="min-480 account-reveice"
				src="@/assets/images/public/reveice.png"
				alt=""
			/> -->
      <div class="document" @click="goDocument">开发文档</div>
      <el-popover
        placement="bottom-start"
        trigger="click"
        popper-class="select-popover"
        @show="toggleChangeIcon"
        @hide="toggleChangeIcon"
      >
        <div class="account-select">
          <div
            class="select-one flex"
            v-for="item in selects"
            :key="item.type"
            @click="handleClick(item)"
          >
            <img :src="item.icon" class="img" alt="" />
            <img :src="item.actIcon" class="img_act" alt="" />
            <span>
              {{ item.label }}
            </span>
          </div>
        </div>
        <div class="flex account-info" slot="reference">
          <!-- <el-avatar
            class="min-480"
            size="medium"
            src="https://dummyimage.com/50x50/000/fff"
          ></el-avatar> -->
          <span style="color: #ffffff; margin: 0 10px">{{ snName }}</span>
          <!-- <span style="color: #ffffff; margin: 0 6px">{{ userName }}</span> -->
          <i
            class="iconfont icon-geren"
            :style="{
              // color: currentSkin ? currentSkin.topColor : '',
              color: '#0077FF',
              fontSize: '24px',
              marginRight: '10px'
            }"
            :class="{ iconOpen: isOpen }"
          ></i>
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import { delCookie } from "@/utils/cookie";
import { logout, userType } from "@/api/index"
import {
  title,
  navMode,
  layoutMode,
  langEnum,
  localLangFlag,
} from "../../../config/project";
// import LogoBox from "../logo";
export default {
  name: "publicNav",
  components: {  },
  props: {
    showBreadcrumb: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pwdShow: false,
      orgShow: false,
      centerShow: false,
      baseBreadcrumb: {
        label: "首页",
        name: "/",
      },
      isOpen: false,
      selects: [
        {
          icon: require("@/assets/images/public/base.png"),
          actIcon: require("@/assets/images/public/base_act.png"),
          label: "基本信息",
          type: "baseInfo",
        },
        {
          icon: require("@/assets/images/public/app.png"),
          actIcon: require("@/assets/images/public/app_act.png"),
          label: "开发应用",
          type: "developApp",
        },
        {
          icon: require("@/assets/images/public/logout.png"),
          actIcon: require("@/assets/images/public/logout_act.png"),
          label: this.$t("public.login_out"),
          type: "logout",
        },
      ],
      title,
      navMode,
      langEnum,
      layoutMode,
      activeIndex: "",
      userName: JSON.parse(localStorage.getItem("account")),
      snName:'',
    };
  },
  mounted() {
    // 监听localhostStorage的数据变化，结合utils/tool.js配合使用
    // window.addEventListener('setItemEvent', function(e) {
    // 	const newdata = JSON.parse(e.newValue)
    // 	console.log('值改变了',newdata)
    // 	this.snName = newdata.sn
    // })
    this.$nextTick(()=>{
      this.snName = sessionStorage.getItem("loginName")
    });
  },
  computed: {
    breadcrumb() {
      let res = [];
      const { meta = {}, matched = [], name = "" } = this.$route;
      res.push({
        label: meta.title,
        name,
      });
      if (matched.length && meta.parant) {
        let one = matched.find((f) => f.name == meta.parant);
        res.push({
          label: one.meta.title,
          name: one.name,
        });
      }
      // res.push(this.baseBreadcrumb);
      return res.reverse();
    },
    isSideOpen() {
      return this.$store.getters.sideNavOpen;
    },
    currentSkin() {
      return this.$store.getters["skin/skinList"].find((f) => f.checked);
    },
    nav() {
      return this.$store.getters.menuList;
    },
    deviceInfo() {
      return this.$store.getters.deviceInfo;
    },
    lang() {
      return this.$store.getters.lang;
    },
    showLang() {
      return this.langEnum.find((f) => f.isCheck);
    },
  },
  watch: {
    $route: {
      handler(val) {
        if (this.navMode == "sideTop") {
          this.activeIndex = val.matched.filter((f) => f.path).length
            ? val.matched.filter((f) => f.path)[0].name
            : "";
          this.$store.commit("permission/SET_TOP_LEVEL", this.activeIndex);
        }
        this.snName = JSON.parse(localStorage.getItem("receiverInfo"))
          ? JSON.parse(localStorage.getItem("receiverInfo")).sn
          : "";
      },
      deep: true,
      immediate: true,
    },
    lang: {
      handler(val) {
        this.langEnum.forEach((item) => {
          if (item.type == val) {
            item.isCheck = true;
          } else {
            item.isCheck = false;
          }
        });
      },
      deep: true,
      immediate: true,
    },
    snName: {
      handler(val) {
        this.snName = val;
      },
    },
  },
  methods: {
    goDocument(){
      this.$router.push({
        name: "document",
      });
    },
    toggleOpenSide() {
      this.$store.commit("TOGGLE_SIDE_OPEN");
    },
    toggleShow() {
      this.$store.commit("skin/TOGGLE_SHOW_SKIN");
    },
    openScreen() {
      // this.$router.push({
      //   name: "member",
      // });
      const { origin = "" } = location;
      window.open(origin ? `${origin}/#/member` : "##");
    },
    toggleChangeIcon() {
      this.isOpen = !this.isOpen;
    },
    handleClick(e) {
      const { type = "" } = e || {};
      if (!type) {
        return;
      }
      if (type == "logout") {
        this.$messageBox
          .confirm(this.$t("public.login_out_message"), this.$t("public.tip"), {
            confirmButtonText: this.$t("public.submit"),
            cancelButtonText: this.$t("public.cancel"),
            type: "warning",
          })
          .then((res) => {
            delCookie("token");
            delCookie("userMessObj");
            this.$store.commit("SET_LOGIN_STATUS", false);
            this.$router.replace({
              name: "login",
            });
            logout().then(res=>{
            	localStorage.removeItem('token')
            	localStorage.removeItem('apiFullRoute')
            	console.log(res)
            })
          })
          .catch((err) => {});
      } else if (type == "baseInfo") {
        this.$router.push({
          name: "baseInfo",
        });
      } else if (type == "developApp") {
        this.$router.push({
          name: "developApp",
        });
      }
    },
    handleSelect(e) {
      this.$router.push({
        name: e,
      });
      // console.log(e);
    },
    handleChangeLang(e) {
      if (e.type != this.showLang.type) {
        this.$store.dispatch("config/setLanguage", e.type);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.publicNav {
  height: 64px;
  background: linear-gradient(90deg, #1353ab 0%, #032664 100%);
  justify-content: space-between;
  line-height: 64px;
  transition: all 0.4s;
  .title {
    height: 100%;
    & > span {
      height: 100%;
      display: inline-block;
      min-width: 210px;
      text-align: center;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #f5f5f5;
    }
    & > i {
      cursor: pointer;
    }
    .breadcrumbSpan {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #deebff;
      cursor: pointer;
    }
  }
  .account {
    .document{
      font-size: 14px;
    }
    .document:hover{
      cursor: pointer;
      text-decoration: underline;
    }
    padding: 0 10px;
    & i {
      color: #fff;
      cursor: pointer;
      font-size: 24px;
      transition-duration: 0.5s;
      display: inline-block;
      & + i {
        margin-left: 20px;
      }
    }
    & > span {
      position: relative;
    }
    &-sn {
      font-size: 16px;
      color: #ffffff;
    }
    &-reveice {
      height: 46px;
      width: auto;
      margin: 0 10px;
    }
    &-info {
      // margin-left: 20px;
      cursor: pointer;
      line-height: unset;
      & i {
        &:last-child {
          // margin-left: 12px;
          font-size: 16px;
        }
      }
    }
    .iconOpen {
      // transform: rotate(180deg);
    }
  }
  .langBox {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    padding: 0 20px;
    cursor: pointer;
    & > img {
      height: 25px;
      width: auto;
    }
    & > span {
      margin: 0 10px;
    }
  }

  .topNav {
    flex: 1;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    &::v-deep .el-menu-item {
      line-height: 54px;
      height: 54px;
    }
    &::v-deep .el-menu-item:not(.is-disabled):hover {
      background-color: rgba(17, 120, 229, 0.6) !important;
    }
    &::v-deep .el-menu-item.is-active {
      // border-color: #1353ab !important;
      background-color: #1178e5 !important;
    }
  }
  .small {
    flex: 1;
    line-height: 48px;
    height: 48px;
    position: relative;
    .langBox {
      padding: 0;
      font-size: 14px;
      & > span {
        margin: 0 4px;
        max-width: 28px;
        overflow: hidden;
      }
    }
    &-icon {
      justify-content: center;
      width: 48px;
      font-size: 18px;
      cursor: pointer;
      height: 48px;
    }
    .flexd {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 50;
    }
    &-title {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      height: 48px;
      max-width: 50%;
      overflow: hidden;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
    }
  }
}
.account-select {
  .select-one {
    padding: 6px 20px;
    transition-duration: 0.4s;
    position: relative;
    line-height: 24px;
    cursor: pointer;
    & > img {
      width: 16px;
      height: 16px;
    }
    .img {
      display: block;
    }
    .img_act {
      display: none;
    }
    &:hover {
      // background-color: $main_color;
      background-color: rgba(22,119,255,0.1);;
      color: #0077FF;
      .img {
        display: none;
      }
      .img_act {
        display: block;
      }
    }
    & > span {
      flex: 1;
      text-align: center;
      padding-left: 8px;
    }
  }
}
.PC {
  // background-image: url("../../../assets/images/public/top_bg.png") !important;
  background-position: center !important;
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
}
</style>

<style lang="scss">
.langList {
  min-width: 180px;
  & > * {
    height: 38px;
    line-height: 38px;
    z-index: 1;
  }
  & > div {
    color: #333;
    cursor: pointer;
  }
  & img {
    height: 25px;
    width: auto;
    margin-right: 10px;
    z-index: 1;
  }
  & span {
    z-index: 1;
  }
  .langListCheck {
    position: relative;

    color: #fff;
    &::before {
      content: "";
      position: absolute;
      width: calc(100% + 26px);
      // background: #539d56;
      height: 100%;
      z-index: 0;
      left: -13px;
    }
  }
}
.select-popover {
  padding: 0;
}
</style>
