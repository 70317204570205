<template>
  <div
    class="safe-top sideNav"
    :class="{ isClose: !isOpen }"
    :style="{
      backgroundImage: `linear-gradient(180deg, ${currentSkin.sideBg[0]} 0%, ${currentSkin.sideBg[1]} 100%)`,
    }"
  >
    <!-- width: isOpen ? `210px` : '64px', -->
    <div class="sideNav-input" v-if="canSearch">
      <el-input
        v-if="isOpen"
        v-model="value"
        placeholder="搜索菜单"
        ref="searchInput"
        suffix-icon="el-icon-search"
      ></el-input>
      <span class="flex-hc" v-else @click="toggleOpenSide">
        <i class="el-icon-search"></i>
      </span>
    </div>
    <div
      class="sideNav-logo"
      :class="{ mobieLogo: deviceInfo.type == 'MOBIE' }"
      v-if="deviceInfo.type == 'MOBIE' || layoutMode == 'sideFull'"
    >
      <logo-box></logo-box>
    </div>
    <!-- style="padding-bottom: 80px;" -->
    <el-menu
      :default-active="currentName"
      :collapse="!isOpen"
      class="el-menu-vertical"
      :default-openeds="openArr"
      @select="handlerSelect"
      background-color="#ffffff"
      active-text-color="#0077FF"
      :text-color="currentSkin.sideColor"
    >
      <sidebar-item
        :navItem="nav"
        :parent="parent"
        :isOpen="isOpen"
      ></sidebar-item>
    </el-menu>
    <div class="sideNav-switch flex" v-if="canSwitch" @click="changeOpen">
      <div>
        <div :class="isOpen ? 'foldImg' : 'foldImg_rotate'"></div>
        <!-- <i class="iconfont icon-a-bianzu2beifen" v-if="deviceInfo.type != 'MOBIE'" @click="toggleOpenSide" :style="{
					color: currentSkin ? currentSkin.topColor : '',
				}"></i>
				<i v-else @click="handleClick" :style="{
					color: currentSkin ? currentSkin.topColor : '',
					fontStyle: 'normal',
					fontSize: '14px',
				}">
					退出登录
				</i> -->
      </div>
    </div>
  </div>
</template>

<script>
import { delCookie } from "@/utils/cookie";
import SidebarItem from "./components/SidebarItem.vue";
import LogoBox from "../logo";
import { navMode, layoutMode, title } from "../../../config/project";
export default {
  components: { SidebarItem, LogoBox },
  name: "sideNav",
  props: {
    canSearch: {
      type: Boolean,
      default: false,
    },
    canSwitch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: "",
      isCollapse: true,
      currentName: "index",
      openArr: [],
      navMode,
      layoutMode,
      title,
    };
  },
  computed: {
    isOpen() {
      return this.$store.getters.sideNavOpen;
    },
    nav() {
      if (this.$store.state.permission.topLevel) {
        return this.$store.getters.menuList.filter(
          (f) => f.name == this.$store.state.permission.topLevel
        ).length
          ? this.$store.getters.menuList.filter(
              (f) => f.name == this.$store.state.permission.topLevel
            )[0].children || []
          : [];
      } else {
        if (this.value) {
          return this.handleInput(this.value, this.$store.getters.menuList);
        } else {
          if (this.$store.getters.model) {
            return this.$store.getters.menuList.filter((f) => {
              return f.meta.type == this.$store.getters.model;
            });
          } else {
            return this.$store.getters.menuList.filter((f) => {
              return !f.meta.type;
            });
          }
        }
      }
    },
    parent() {
      return this.$route.meta.parant;
    },
    currentSkin() {
      return this.$store.getters["skin/skinList"].find((f) => f.checked);
    },
    deviceInfo() {
      return this.$store.getters.deviceInfo;
    },
  },
  watch: {
    $route: {
      handler(nValue, oValue) {
        if (this.currentName != nValue.name) {
          this.currentName = nValue.name;
        }
      },
      deep: true,
      immediate: true,
    },
    nav: {
      handler(val) {
        if (!this.value) {
          this.openArr = [this.$route.meta.parant];
          return;
        }
        if (val.length) {
          let temp = [];
          val.forEach((f) => {
            temp.push(f.name);
          });
          this.openArr = temp;
        }
      },
      deep: true,
    },
  },
  methods: {
    changeOpen() {
      this.$store.commit("TOGGLE_SIDE_OPEN");
    },
    handlerSelect(index) {
      if (index == this.currentName) {
        return;
      }
      this.$router.push({
        name: index,
      });
    },
    toggleOpenSide() {
      this.$store.commit("TOGGLE_SIDE_OPEN");
      if (this.canSearch) {
        this.$nextTick(() => {
          this.$refs.searchInput.focus();
        });
      }
    },
    handleInput(str, arr = []) {
      let res = [];
      arr.forEach((f) => {
        let temp = {
          id: f.id,
          label: f.label,
          name: f.name,
        };
        if (f.children && f.children.length) {
          let _arr = this.handleInput(str, f.children);
          if (_arr.length) {
            if (f.icon) temp.icon = f.icon;
            temp.children = _arr;
            res.push(temp);
          }
        } else {
          if (f.label.indexOf(str) != -1) {
            if (f.icon) temp.icon = f.icon;
            res.push(temp);
          }
        }
      });
      return res;
    },
    handleClick() {
      this.$messageBox
        .confirm("确认退出登录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then((res) => {
          delCookie("token");
          delCookie("userMessObj");
          this.$store.commit("SET_LOGIN_STATUS", false);
          this.$router.replace({
            name: "login",
          });
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.sideNav {
  background-image: linear-gradient(180deg, #ffffff 0%, #f0f0f0 100%);
  height: 100%;
  // transition: width 0.3s;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  // padding-bottom: 40px;
  &::-webkit-scrollbar {
    display: none;
  }

  &-input {
    padding: 10px 4px;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    z-index: 2;

    &::v-deep .el-input__inner {
      background-color: #f2f2f2;
    }

    & > span {
      border: 1px solid #dcdfe6;
      //   display: block;
      height: 38px;
      line-height: 40px;
      overflow: hidden;
      background-color: #f2f2f2;
      border-radius: 4px;
      font-size: 14px;
      color: #c0c0c0;
      cursor: pointer;
    }
  }

  &-logo {
    height: 64px;
    line-height: 64px;
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
    background-color: rgb(83, 157, 86);
  }

  &-switch {
    padding: 0 4px;
    position: sticky;
    height: 40px;
    line-height: 40px;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgb(83, 157, 86);
    z-index: 2;
    justify-content: center;

    & > * {
      cursor: pointer;
    }
  }

  &::v-deep .el-menu {
    border-width: 0;
    // background-color: transparent !important;
  }

  & em {
    padding-right: 20px;

    & i {
      font-size: 20px;
      color: #ffffff;
    }
  }

  .checked {
    color: #ffffff;

    &::v-deep i {
      color: #ffffff;
    }
  }

  .mobieLogo > div {
    padding: 10px 0px;
  }

  /*隐藏文字*/
  &::v-deep .el-menu--collapse .el-submenu__title span {
    display: none;
  }

  /*隐藏 > */
  &::v-deep .el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
    display: none;
  }

  &::v-deep .el-submenu__title:hover {
    background-color: $nav_active_bg !important;
  }

  &::v-deep .el-menu-item:focus,
  .el-menu-item:hover {
    outline: 0;
    background-color: rgba($color: $nav_active_bg, $alpha: 0.6) !important;
  }

  &::v-deep .el-menu-item.is-active {
    // border-left: 5px solid #0077FF;
    background-color: $nav_active_bg !important;
  }

  &::v-deep .el-menu-item {
    // border-left: 5px solid #FFFFFF;
    height: 54px;
    line-height: 54px;
    padding-right: 20px;
    color: $nav_color;
    // display: flex;
    // align-items: center;
    display: -webkit-box;
  }

  &::v-deep .el-submenu__title {
    height: 54px;
    line-height: 54px;
    color: $nav_color;
    display: flex;
    align-items: center;
  }
}

.isClose {
  // ::v-deep li.el-menu-item span {
  // opacity: 0;
  // transition-duration: 0.5s;
  // }
  ::v-deep .el-tooltip {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}
.foldImg,
.foldImg_rotate {
  width: 25px;
  height: 25px;
  // background-image: require("../../assets/images/public/foldMenu.png");
  background-image: url("../../../assets/images/public/foldMenu.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.foldImg_rotate {
  transform: rotate(180deg);
}
</style>