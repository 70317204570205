<template>
	<div
		class="manage flex-dc"
		:class="{ openNav: deviceInfo.type == 'MOBIE' && isOpen }"
	>
		<div
			class="max-480 flex-dc mobieNav"
			:class="{ hiddenSide: deviceInfo.type == 'MOBIE', showSide: isOpen }"
		>
			<side-nav></side-nav>
		</div>
		<template v-if="layoutMode == 'navFull'">
			<div class="header">
				<public-nav></public-nav>
			</div>
			<div class="manage-main flex" style="flex: 1; overflow: hidden">
				<div class="min-480 side" v-if="!hiddenSide">
					<side-nav></side-nav>
				</div>
				<div class="wrapper flex-dc">
					<!-- <view-nav class="min-480" v-if="!hiddenSide"></view-nav> -->
					<div class="main">
						<keep-alive v-if="useRouteCache">
							<router-view />
						</keep-alive>
						<router-view v-else />
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="manage-main flex" style="flex: 1; overflow: hidden">
				<div class="side" v-if="!hiddenSide">
					<side-nav></side-nav>
				</div>
				<div class="wrapper flex-dc">
					<div class="header">
						<public-nav></public-nav>
					</div>
					<!-- <view-nav class="min-480" v-if="!hiddenSide"></view-nav> -->
					<div class="main">
						<keep-alive v-if="useRouteCache">
							<router-view />
						</keep-alive>
						<router-view v-else />
					</div>
				</div>
			</div>
		</template>
		<config-diawer
			v-if="isShowConfig && deviceInfo.type == 'PC'"
		></config-diawer>
		<skin></skin>
	</div>
</template>

<script>
	import PublicNav from "@/components/public/publicNav/index";
	import SideNav from "@/components/public/sideNav/index.vue";
	// import ViewNav from "@/components/public/viewNav/index.vue";
	import skin from "@/components/public/skin/index.vue";
	import configDiawer from "@/components/public/configDiawer/index.vue";
	import { useRouteCache, layoutMode } from "@/config/project";
	export default {
		components: {
			PublicNav,
			SideNav,
			skin,
			// ViewNav,
			configDiawer,
		},
		data() {
			return {
				useRouteCache,
				layoutMode,
				hiddenSide: false,
			};
		},
		computed: {
			isOpen() {
				return this.$store.getters.sideNavOpen;
			},
			isLogin() {
				return this.$store.getters.isLogin;
			},
			isShowConfig() {
				return this.$store.getters.isShowConfig;
			},
			deviceInfo() {
				return this.$store.getters.deviceInfo;
			},
		},
		watch: {
			deviceInfo: {
				handler(val) {
					const { type } = val;
					if (type == "MOBIE") {
						this.$store.commit("SET_SIDE_OPEN", false);
					}
				},
				deep: true,
				immediate: true,
			},
			$route: {
				handler(val) {
					this.hiddenSide = val.meta.hiddenSide;
				},
				deep: true,
				immediate: true,
			},
		},
		mounted() {},
		methods: {},
	};
</script>

<style lang="scss" scoped>
	::v-deep{
		.el-menu--collapse{
			height: 100%;
		}
	}
	.manage {
		// overflow: hidden;
		transition-duration: 0.5s;
		// min-width: 1400px;
		.mobieNav {
			::v-deep .el-menu-vertical:not(.el-menu--collapse) {
				width: 100%;
				min-height: calc(100% - 64px - 40px);
			}
			::v-deep .el-submenu__title i {
				color: var(--nav_color);
			}
		}
		.header {
			width: 100%;
		}
		.side {
			height: 100%;
		}
		.hiddenSide {
			position: fixed;
			left: calc(-100% + 48px);
			top: 0;
			height: 100%;
			width: calc(100% - 48px);
		}
		&-main {
			justify-content: space-between;
		}
		.wrapper {
			flex: 1;
			height: 100%;
			background-color: #fff;
			overflow: hidden;
			.main {
				flex: 1;
				overflow: hidden;
				&::v-deep .select {
					background-color: #fff;
					padding: 0 24px;
					min-height: 64px;
				}
				&::v-deep .content {
					background-color: #fff;
					margin-top: 10px;
				}
				&::v-deep .pageTitle {
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #333333;
					line-height: 22px;
					line-height: 50px;
				}
				.btn {
					width: 70px;
					height: 30px;
					line-height: 30px;
					background-color: $main_color;
					border-color: $main_color;
					padding: 0;
				}
			}
		}
	}
	.openNav {
		transform: translateX(calc(100% - 48px));
		position: relative;
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $mask_color;
		}
	}
</style>

